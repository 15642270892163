import { createContext, FC, PropsWithChildren, useMemo } from 'react'
import {
  NodeType,
  PageNodeProfileVariants,
  TPageNodeProfile,
} from 'boards-web-ui'

import { ProfileFeatures } from '@models/UserProfile'
import useProfileFeatureFlag from '@hooks/useProfileFeatureFlag'

import usePageProfileForm from '../hooks/usePageProfileForm'
import type { ImageUploadProvider, PageProfileState } from '../models'

const DEFAULT_STATE: PageProfileState = {
  initialState: undefined,
  isProfileVariationAvailable: false,
  pageProfileFormState: {
    cropping: false,
    imagePreview: null,
    isValid: true,
    formState: {
      type: NodeType.PROFILE,
      profile: {
        name: '',
        title: '',
        image: '',
        company: '',
        variation: PageNodeProfileVariants.v0,
      },
    },
    errors: {},
    pending: false,
    loading: false,
    onChange: (e) => {
      throw new Error(
        `trying to update TPageNodeProfile field ${e.target.name}, but onChange handler doesn't exist.`,
      )
    },
    onSubmit: () => {
      throw new Error(
        `trying to submit pageProfileForm, but onSubmit handler doesn't exist.`,
      )
    },
    onCancel: () => {
      throw new Error(
        `trying to cancel pageProfileForm changes, but onCancel handler doesn't exist.`,
      )
    },
    uploadImage: () => {
      throw new Error(
        `trying to cancel pageProfileForm changes, but onCancel handler doesn't exist.`,
      )
    },
  },
}

export const PageProfileContext = createContext<PageProfileState>(DEFAULT_STATE)

type PageProfileProviderProps = PropsWithChildren<{
  initialState?: TPageNodeProfile
  imageUploadProvider: ImageUploadProvider
  onSave: (TPageNodeProfile: TPageNodeProfile) => void
  onCancel: () => void
}>
const PageProfileProvider: FC<PageProfileProviderProps> = ({
  initialState,
  imageUploadProvider,
  onCancel,
  onSave,
  children,
}) => {
  const { isFeatureAvailable: isProfileVariationAvailable } =
    useProfileFeatureFlag(ProfileFeatures.PROFILE_SECTION_VARIATIONS)

  const pageProfileFormState = usePageProfileForm({
    initialState,
    imageUploadProvider,
    onSubmitHandler: onSave,
    onCancelHandler: onCancel,
  })

  const state = useMemo<PageProfileState>(
    () => ({
      ...DEFAULT_STATE,
      initialState,
      pageProfileFormState,
      isProfileVariationAvailable,
    }),
    [initialState, pageProfileFormState, isProfileVariationAvailable],
  )

  return (
    <PageProfileContext.Provider value={state}>
      {children}
    </PageProfileContext.Provider>
  )
}

export default PageProfileProvider
