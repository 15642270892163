import {
  AdditionalFields,
  AdditionalFieldType,
  CheckoutFormProduct,
  CheckoutFormProducts,
  FormField,
  getLinkType,
  isPageNodeProfile,
  NODE_LINK_TYPES,
  TPageNode,
} from 'boards-web-ui'
import { DocumentData } from 'firebase/firestore'

import {
  isAudioNode,
  isCheckoutForm,
  isFormNode,
  isHeadlineNode,
  isImageNode,
  isLinkNode,
  isParagraphNode,
  isPdfNode,
  isVideoNode,
  isVideoStreamingNode,
} from '@helpers/nodeType'
import { Node, NodeType } from '../../../app/models'

const parseButtonTypes = (type: string, src: string): string | undefined => {
  const BUTTON_TYPES: { [id: string]: NODE_LINK_TYPES } = {
    zoom: NODE_LINK_TYPES.ZOOM,
    calendly: NODE_LINK_TYPES.CALENDLY,
    cash: NODE_LINK_TYPES.CASH,
    cashapp: NODE_LINK_TYPES.CASH,
    messenger: NODE_LINK_TYPES.FACEBOOK,
    email: NODE_LINK_TYPES.MAIL,
    paypal: NODE_LINK_TYPES.PAYPAL,
    phone: NODE_LINK_TYPES.PHONE,
    link: NODE_LINK_TYPES.REGULAR,
    sms: NODE_LINK_TYPES.SMS,
    telegram: NODE_LINK_TYPES.TELEGRAM,
    venmo: NODE_LINK_TYPES.VENMO,
    whatsapp: NODE_LINK_TYPES.WHATS_APP,
  }

  return BUTTON_TYPES[type] || getLinkType(src) || undefined
}

export const transformNodePage = async (
  boardId: string,
  pageId: string,
  id: string,
  node: DocumentData,
  properties: DocumentData,
): Promise<Node> => {
  if (isCheckoutForm(node)) {
    const { shippingFee, minOrder, currency } = node.form
    const additionalFields: AdditionalFields = []
    const products: CheckoutFormProducts = []

    node.form?.fields?.forEach((field: FormField) => {
      if (field.type === 'product') {
        return products.push(field as unknown as CheckoutFormProduct)
      }

      const { id: fieldId, label, required, type } = field
      return additionalFields.push({
        id: fieldId,
        type: type as AdditionalFieldType,
        value: label || '',
        required,
      })
    })

    return {
      id,
      title: node.title || '',
      type: NodeType.CHECKOUT,
      integrationState: undefined,
      form: {
        ...node.form,
        additionalFields,
        products,
        shippingFee,
        minOrder,
        currency,
      },
    } as Node
  }

  if (isFormNode(node)) {
    return {
      id,
      title: node.title,
      type: NodeType.FORM,
      form: node.form,
      integrationState: undefined,
    } as Node
  }

  if (isImageNode(node)) {
    return {
      id,
      icon: '',
      title: node.titlez,
      text: node.text,
      type: NodeType.IMAGE,
      image: node.text,
      width: properties?.w,
      height: properties?.h,
    } as Node
  }

  if (isPdfNode(node)) {
    return {
      id,
      title: node.title,
      text: node.text,
      type: NodeType.PDF,
      thumbnail: properties?.i,
    } as Node
  }

  if (isAudioNode(node)) {
    return {
      id,
      icon: '',
      title: node.title,
      text: node.text,
      type: NodeType.AUDIO,
      duration: parseInt(properties?.d, 10),
      size: properties?.s,
      shortcut: node.shortcut,
    }
  }

  if (isVideoNode(node)) {
    return {
      id,
      title: node.title,
      text: node.text,
      type: NodeType.VIDEO,
      thumbnail: properties?.i,
    } as Node
  }

  if (isVideoStreamingNode(node)) {
    return {
      id,
      type: NodeType.VIDEO_STREAMING,
      text: node.text,
      title: node.title,
      shortcut: node.shortcut,
      thumbnail: properties?.i,
      isProcessing:
        properties?.x === 'processing' ||
        (properties?.x === undefined && properties?.w === undefined),
      width: properties?.w,
      height: properties?.h,
    } as Node
  }

  if (isLinkNode(node)) {
    return {
      id,
      type: NodeType.BUTTON,
      text: node.text,
      title: node.title,
      diallingCode: properties?.dc,
      countryCode: properties?.cc?.toLowerCase(),
      link_type: parseButtonTypes(properties?.ap, node.text),
    } as Node
  }

  if (isParagraphNode(node)) {
    return {
      id,
      type: NodeType.PARAGRAPH,
      text: node.text,
      title: node.title,
    } as Node
  }

  if (isHeadlineNode(node)) {
    return {
      id,
      type: NodeType.HEADLINE,
      text: node.text,
      title: node.title,
    } as Node
  }

  if (isPageNodeProfile(node as TPageNode)) {
    return {
      id,
      ...node,
    } as Node
  }

  return {
    id,
    type: NodeType.TEXT,
    text: node.text,
    title: node.title,
  } as Node
}
