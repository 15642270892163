/* eslint-disable no-param-reassign */
import { omit } from 'lodash-es'
import {
  getFolderId,
  getNodeId,
  getRootFolderId,
} from '@helpers/NodeIdGenerator'
import { removeUndefinedKeysFromObject } from '@helpers/object'
import {
  ACTION_ACTION_TYPE,
  BOARD_ACTION_TYPE,
  BOARDS_ORDER_ACTION_TYPE,
  BULK_ACTION_TYPE,
  BUTTON_ACTION_TYPE,
  CAMPAIGN_ACTION_TYPE,
  CLIENT_CONFIG_TYPE,
  COMMON_ACTION_TYPE,
  CONTENT_ACTION_TYPE,
  EDIT_LINK_ACTION_TYPE,
  FOLDER_ACTION_TYPE,
  GOOGLE_SHEETS_ACTION_TYPE,
  MEMBERS_ACTION_TYPE,
  NODE_ACTION_TYPE,
  PAGE_ACTION_TYPE,
  PERMISSION_ACTION_TYPE,
  PROFILE_INFO_ACTION_TYPE,
  SET_PERSIST_USER,
  SUBSCRIPTIONS_ACTION_TYPE,
  TEMPLATES_ACTION_TYPE,
  TOAST_ACTION_TYPE,
  UNDO_REDO_ACTION_TYPE,
  UPDATES_ACTION_TYPE,
  UPLOAD_ACTION_TYPE,
  USER_ACTION_TYPE,
  VIDEO_ACTION_TYPE,
} from '../actions'

import campaignReducers from './campaign'

import * as UndoStack from './UndoStack'
import * as FolderManagement from './FolderManagement'
import { NodeType } from '../app/models'
import { copyNodesReducer } from './nodeReducers/copyNodesReducer'
import { copyTemplateReducer } from './nodeReducers/copyTemplateReducer'
import { initialState } from './initialState'

function createBoard(state, owner, bid, properties, picker, extraProps) {
  const boardId = `${owner}-${bid}`
  const rootFolderId = getRootFolderId(boardId)

  const board = {
    id: bid,
    isOwn: true,
    role: 'own',
    action: {},
    rootFolderId,
    key: properties.key,
    icon: properties.icon,
    title: properties.title,
    isActive: true,
    options: {
      checkoutAllowed: true,
      copyAllowed: true,
      formsAllowed: true,
      insightsAllowed: false,
      notCountedTowardsLimit: false,
      shareAllowed: true,
      shareFolderAllowed: true,
    },
    ...extraProps,
  }
  const rootFolder = {
    id: rootFolderId,
    icon: properties.icon,
    title: properties.title,
    owner: properties.owner,
    board: properties.board,
    content: [],
  }

  const pickerStatePath = picker
    ? setFolderPath(state, { picker, boardId, folderId: rootFolderId })
    : {}

  return {
    ...state,
    boards: {
      ...state.boards,
      [boardId]: board,
    },
    folders: FolderManagement.addFolder(state.folders, boardId, rootFolder),
    ...pickerStatePath,
  }
}

function createFakeBoard(state, owner, bid, properties) {
  return {
    ...createBoard(state, owner, bid, properties),
    profileInfo: {
      ...state.profileInfo,
      activeBoards: [...state.profileInfo.activeBoards, bid],
      usage: {
        ...state.profileInfo.usage,
        boards: state.profileInfo.usage.boards + 1,
      },
    },
  }
}

function createPagesBoard(state, owner, bid, properties) {
  return createBoard(state, owner, bid, properties, undefined, {
    type: 'pages',
    isPageBoard: true,
  })
}

function addFolder(state, boardId, fid, key, properties) {
  const nodeId = getFolderId(0, key)
  return setProperties(state, boardId, fid, nodeId, properties)
}

function addPage(state, boardId, fid, key, properties) {
  const nodeId = getFolderId(0, key)
  const node = {
    id: nodeId,
    type: 'page',
    ...properties,
  }
  const folder = {
    id: nodeId,
    icon: properties.icon,
    isPage: true,
    title: '',
    content: [],
    ...properties,
  }

  let folders = FolderManagement.addNodes(state.folders, boardId, fid, [node])
  folders = FolderManagement.addFolder(folders, boardId, folder)

  return {
    ...state,
    folders,
    app: {
      ...state.app,
      currentFolderId: nodeId,
    },
  }
}

function addFakeFolder(state, boardId, fid, key, properties, isPage, picker) {
  const nodeId = getFolderId(0, key)

  const node = {
    id: nodeId,
    type: isPage ? 'page' : 'folder',
    ...properties,
  }

  const folder = {
    id: nodeId,
    key,
    icon: properties.icon,
    isPage,
    title: '',
    content: [],
    ...properties,
  }

  let folders = FolderManagement.addNodes(state.folders, boardId, fid, [node])
  folders = FolderManagement.addFolder(folders, boardId, folder)

  // When creating a folder from the destination picker
  // it should be opened in the destination picker right after creation
  const pickerStatePath = picker
    ? setFolderPath(state, { picker, boardId, folderId: folder.id })
    : {}

  return {
    ...state,
    ...pickerStatePath,
    folders,
  }
}

function addContent(state, boardId, folder, properties, key) {
  const nodeId = getNodeId(0, key)

  const node = {
    ...properties,
    id: nodeId,
  }
  return {
    ...state,
    folders: FolderManagement.addNodes(state.folders, boardId, folder, [node]),
    app: {
      ...state.app,
      undoStack: undoStackWithContentAdded(state, boardId),
      currentFolderId: folder,
    },
  }
}

function setFormIntegrationState(
  state,
  boardId,
  folderId,
  nodeId,
  integrationState,
) {
  return {
    ...state,
    ...FolderManagement.updateFoldersFormIntegrationState(
      state,
      boardId,
      folderId,
      nodeId,
      integrationState,
    ),
  }
}

function setProperties(state, boardId, folderId, nodeId, properties) {
  return {
    ...state,
    ...FolderManagement.updateFolders(
      state,
      boardId,
      folderId,
      nodeId,
      properties,
    ),
    app: {
      ...state.app,
      undoStack: undoStackWithContentAdded(state, boardId),
    },
  }
}

function moveNodes(state, board, folder, nodeIds, fromFolder, beforeNodeId) {
  const nodes = FolderManagement.getNodes(
    state.folders,
    board,
    fromFolder,
    nodeIds,
  )
  let folders = FolderManagement.deleteNodes(
    state.folders,
    board,
    fromFolder,
    nodeIds,
  )
  folders = FolderManagement.addNodes(
    folders,
    board,
    folder,
    nodes,
    beforeNodeId,
  )

  return {
    ...state,
    folders,
    app: {
      ...state.app,
      undoStack: undoStackWithContentAdded(state, board),
      bulk: initialState.app.bulk,
    },
  }
}

function deleteNodes(state, boardId, folderId, nodes) {
  return {
    ...state,
    folders: FolderManagement.deleteNodes(
      state.folders,
      boardId,
      folderId,
      nodes,
    ),
    app: {
      ...state.app,
      undoStack: undoStackWithContentAdded(state, boardId),
    },
  }
}

function editLinkError(state, errorMessage) {
  return {
    ...state,
    app: {
      ...state.app,
      nodeEditor: {
        ...state.app.nodeEditor,
        errorMessage,
        editLink: {
          errorMessage,
        },
      },
    },
  }
}

function editLinkPageStep(state, pageStep) {
  return {
    ...state,
    app: {
      ...state.app,
      nodeEditor: {
        ...state.app.nodeEditor,
        pageStep,
      },
    },
  }
}

function editLinkLoading(state, loading) {
  return {
    ...state,
    app: {
      ...state.app,
      nodeEditor: {
        ...state.app.nodeEditor,
        loading,
      },
    },
  }
}

function editLinkTitle(state, title) {
  return {
    ...state,
    app: {
      ...state.app,
      nodeEditor: {
        ...state.app.nodeEditor,
        nodeTitle: title,
      },
    },
  }
}

function resetEditLinkState(state) {
  return {
    ...state,
    app: {
      ...state.app,
      nodeEditor: {
        ...state.app.nodeEditor,
        pageStep: 1,
        nodeTitle: '',
        loading: false,
        editLink: {
          ...state.app.nodeEditor.editLink,
          errorMessage: null,
        },
        nodeVideo: {
          ...state.app.nodeEditor.nodeVideo,
          hasError: false,
        },
      },
    },
  }
}

function videoError(state, error) {
  return {
    ...state,
    app: {
      ...state.app,
      nodeEditor: {
        ...state.app.nodeEditor,
        nodeVideo: {
          ...state.app.nodeEditor.nodeVideo,
          hasError: error,
        },
      },
    },
  }
}

function videoStreamingUrl(state, url) {
  return {
    ...state,
    app: {
      ...state.app,
      nodeEditor: {
        ...state.app.nodeEditor,
        nodeVideo: {
          ...state.app.nodeEditor.nodeVideo,
          videoUrl: url,
        },
      },
    },
  }
}

function videoStreamingId(state, id) {
  return {
    ...state,
    app: {
      ...state.app,
      nodeEditor: {
        ...state.app.nodeEditor,
        nodeVideo: {
          ...state.app.nodeEditor.nodeVideo,
          videoId: id,
        },
      },
    },
  }
}

const setFormErrors = (state, formErrors) => {
  return {
    ...state,
    app: {
      ...state.app,
      pageEditor: {
        ...state.app.pageEditor,
        formErrors,
      },
    },
  }
}

const setButtonError = (state, buttonError) => {
  return {
    ...state,
    app: {
      ...state.app,
      pageEditor: {
        ...state.app.pageEditor,
        buttonError,
      },
    },
  }
}

const setButtonType = (state, buttonType) => {
  return {
    ...state,
    app: {
      ...state.app,
      pageEditor: {
        ...state.app.pageEditor,
        buttonType,
      },
    },
  }
}
const setButtonGeoData = (state, geoData) => {
  return {
    ...state,
    app: {
      ...state.app,
      pageEditor: {
        ...state.app.pageEditor,
        buttonGeoData: geoData,
      },
    },
  }
}

const setIsGoogleSheetsUrlValid = (state, isUrlValid) => {
  return {
    ...state,
    app: {
      ...state.app,
      pageEditor: {
        ...state.app.pageEditor,
        isGoogleSheetsUrlValid: isUrlValid,
      },
    },
  }
}

function deleteFolder(state, action) {
  const { board, fid } = action

  const boardFolders = state.folders[board]
  delete boardFolders[fid]

  return {
    ...state,
    folders: {
      ...state.folders,
      [board]: boardFolders,
    },
  }
}

function deleteBoard(state, boardId) {
  const { boards } = state
  const board = boards[boardId]
  delete boards[boardId]

  return {
    ...state,
    boards,
    profileInfo: {
      ...state.profileInfo,
      usage: {
        ...state.profileInfo.usage,
        boards: board.isActive
          ? state.profileInfo.usage.boards - 1
          : state.profileInfo.usage.boards,
      },
    },
  }
}

function removeBoard(state, boardId) {
  const { boards } = state
  delete boards[boardId]

  return {
    ...state,
    boards,
  }
}

function undoStackWithContentAdded(state, boardId) {
  const board = state.boards[boardId]
  const folders = state.folders[boardId]

  return UndoStack.withContentAdded(state.app.undoStack, boardId, {
    board,
    folders,
  })
}

function stateEndEditingContentNode(state) {
  return {
    ...state,
    app: {
      ...state.app,
      editing: false,
      selectedNodeId: undefined,
    },
  }
}

function undoRedo(state, boardId, undo) {
  if (boardId === undefined) return state

  const board = state.boards[boardId]
  const folders = state.folders[boardId]
  if (board === undefined) return state

  const result = undo
    ? UndoStack.undo(state.app.undoStack, boardId, { board, folders })
    : UndoStack.redo(state.app.undoStack, boardId, { board, folders })

  if (result.content === undefined) return state

  return {
    ...state,
    boards: {
      ...state.boards,
      [boardId]: {
        ...result.content.board,
        action: { id: result.content.board.id },
      },
    },
    folders: {
      ...state.folders,
      [boardId]: result.content.folders,
    },
    app: {
      ...state.app,
      undoStack: result.undoStack,
      selectedNodeId: undefined,
    },
  }
}

function resetUndoStack(state) {
  const { undo, redo } = state.app.undoStack
  if (undo.length === 0 && redo.length === 0) {
    return state
  }

  return {
    ...state,
    app: {
      ...state.app,
      undoStack: initialState.app.undoStack,
    },
  }
}

function addMembers(state, boardId, emails, permission) {
  const permissions = emails.reduce((acc, email) => {
    return {
      ...acc,
      [email]: {
        recipient: email,
        permission,
      },
    }
  }, {})

  return {
    ...state,
    permissions: {
      ...state.permissions,
      [boardId]: {
        ...state.permissions[boardId],
        ...permissions,
      },
    },
  }
}

function removeMembers(state, boardId, emails) {
  return {
    ...state,
    permissions: {
      ...state.permissions,
      [boardId]: omit(state.permissions[boardId], emails),
    },
  }
}

function updatePermission(state, boardId, email, permission) {
  return {
    ...state,
    permissions: {
      ...state.permissions,
      [boardId]: {
        ...state.permissions[boardId],
        [email]: {
          ...state.permissions[boardId][email],
          permission,
        },
      },
    },
  }
}

function stateSetPermission(state, boardId, permissions, final) {
  const { permissionsLoaded } = state
  if (final) {
    permissionsLoaded[boardId] = true
  }

  return {
    ...state,
    permissions: {
      ...state.permissions,
      [boardId]: {
        ...state.permissions[boardId],
        ...permissions,
      },
    },
    permissionsLoaded,
  }
}

function stateDeletePermission(state, board, recipient) {
  const permission = state.permissions[board]

  if (permission[recipient]) {
    delete permission[recipient]
  }

  return {
    ...state,
    app: {
      ...state.app,
      permissions: {
        ...state.app.permissions,
        [board]: permission,
      },
    },
  }
}

function stateSetBoardsOrder(state, order) {
  return {
    ...state,
    app: {
      ...state.app,
      content: {
        order,
      },
    },
  }
}

function stateSetUserContentSettings(state, settings) {
  return {
    ...state,
    app: {
      ...state.app,
      content: {
        ...state.app.content,
        ...settings,
      },
    },
  }
}

function setInvite(state, { boardId, invite }) {
  return {
    ...state,
    invites: {
      ...state.invites,
      [boardId]: invite,
    },
  }
}

function stateSetLoading(state, loading) {
  return {
    ...state,
    app: {
      ...state.app,
      loading,
    },
  }
}

function stateSetBulkAction(state, boardId, folderId, shortcutId) {
  return {
    ...state,
    app: {
      ...state.app,
      bulk: {
        ...state.app.bulk,
        contentId: boardId,
        folderId,
        shortcutId,
      },
    },
  }
}

function stateSetBulkActionType(state, action) {
  return {
    ...state,
    app: {
      ...state.app,
      bulk: {
        ...state.app.bulk,
        action,
      },
    },
  }
}

function stateToggleNodeBulkAction(state, nodeId, nodeType) {
  const { nodes } = state.app.bulk
  const isNodeInBulk = Boolean(nodes.find(({ id }) => id === nodeId))

  const updatedNodes = isNodeInBulk
    ? nodes.filter(({ id }) => id !== nodeId)
    : [...nodes, { id: nodeId, nodeType }]

  return {
    ...state,
    app: {
      ...state.app,
      bulk: {
        ...state.app.bulk,
        nodes: updatedNodes,
      },
    },
  }
}

function setTemplates(state, data) {
  return {
    ...state,
    templates: data,
  }
}

function stateResetBulkAction(state) {
  if (state.app.bulk.contentId === undefined) {
    return state
  }
  return {
    ...state,
    app: {
      ...state.app,
      bulk: {
        action: undefined,
        contentId: undefined,
        folderId: undefined,
        type: undefined,
        nodes: [],
      },
    },
  }
}

function receiveUpdatesMessages(state, { boardId, messages }) {
  return {
    ...state,
    updates: {
      ...state.updates,
      [boardId]: messages,
    },
  }
}

function receiveReadUnReadMessages(state, { boardId, data }) {
  return {
    ...state,
    updatesReadUnRead: {
      ...state.updatesReadUnRead,
      [boardId]: data,
    },
  }
}

function deleteUpdatesMessage(state, boardId, mid) {
  return {
    ...state,
    updates: {
      ...state.updates,
      [boardId]: state.updates[boardId].filter(
        (_message) => _message.id !== mid,
      ),
    },
  }
}

function saveUnPostedUpdatesMessage(state, { postMessageData }) {
  return {
    ...state,
    updatesUnPostMessages: {
      ...state.updatesUnPostMessages,
      [postMessageData.board]: {
        ...postMessageData.properties,
      },
    },
  }
}

function removeUnPostedUpdatesMessage(state, { board }) {
  const { updatesUnPostMessages } = state

  delete updatesUnPostMessages[board]

  return {
    ...state,
    updatesUnPostMessages,
  }
}

function addUpload(state, id) {
  return {
    ...state,
    app: {
      ...state.app,
      uploading: [...state.app.uploading, id],
    },
  }
}

function removeUpload(state, id) {
  return {
    ...state,
    app: {
      ...state.app,
      uploading: state.app.uploading.filter((u) => u !== id),
    },
  }
}

function setUndoAction(state, boardId, content) {
  return {
    ...state,
    undoActions: {
      ...state.undoActions,
      [boardId]: content,
    },
  }
}

function setActionInProgress(state, actionInProgress = true) {
  return {
    ...state,
    app: {
      ...state.app,
      actionInProgress,
    },
  }
}

function syncProfileInformation(state, { info }) {
  return {
    ...state,
    profileInfo: {
      ...info,
    },
    profileIsLoaded: true,
  }
}

function setPayPalAuthorization(state, { status }) {
  return {
    ...state,
    profileInfo: {
      ...state.profileInfo,
      payPalAuthorization: {
        ...state.profileInfo.payPalAuthorization,
        status,
      },
    },
  }
}

function setUiState(state, { property, value }) {
  return {
    ...state,
    profileInfo: {
      ...state.profileInfo,
      uiState: {
        ...state.profileInfo.uiState,
        [property]: value,
      },
    },
  }
}

function setClientConfig(state, data, feature) {
  return {
    ...state,
    app: {
      ...state.app,
      clientConfig: {
        ...state.app.clientConfig,
        [feature]: data,
      },
    },
  }
}

function setBoard(state, boardId, board) {
  return {
    ...state,
    boards: {
      ...state.boards,
      [boardId]: board,
    },
  }
}

function setFolders(state, boardId, folders) {
  /**
   * This is a workaround to prevent flush metadata from button keys
   *
   * Problem: after update a button key, the first snapshot will come with
   * undefined metadata, this will keep previous metadata values
   */
  const foldersWithPreviousMetadataValues = Object.keys(folders).reduce(
    (acc, folderId) => {
      const prevFolder = state?.folders[boardId]
      const prevContent = prevFolder ? prevFolder[folderId]?.content : undefined
      const content = folders[folderId].content.map((node) => {
        if (node.type === NodeType.BUTTON) {
          const prevNode = prevContent?.find(({ id }) => id === node.id)

          if (prevNode) {
            return {
              ...prevNode,
              ...removeUndefinedKeysFromObject(node),
            }
          }
        }

        if (node.type === NodeType.FORM || node.type === NodeType.CHECKOUT) {
          const prevNode = prevContent?.find(({ id }) => id === node.id)

          if (prevNode) {
            const updatedNode = {
              ...prevNode,
              form: {
                ...prevNode.form,
                ...node.form,
              },
              integrationState: {
                ...prevNode?.integrationState,
                ...node?.integrationState,
              },
            }
            return updatedNode
          }
        }

        return node
      })

      return {
        ...acc,
        [folderId]: {
          ...folders[folderId],
          content,
        },
      }
    },
    {},
  )

  return {
    ...state,
    folders: {
      ...state.folders,
      [boardId]: {
        ...state.folders[boardId],
        ...foldersWithPreviousMetadataValues,
      },
    },
  }
}

function setAvailableSubscriptions(state, { plans, current }) {
  return {
    ...state,
    plans: {
      ...state.plans,
      status: 'loaded',
      data: { plans, current },
    },
  }
}

function setAvailableSubscriptionsLoading(state, discountId) {
  return {
    ...state,
    plans: {
      ...state.plans,
      discountId,
      status: 'loading',
    },
  }
}

function renewCurrentSubscription(state) {
  return {
    ...state,
    plans: {
      ...state.plans,
      data: {
        ...state.plans.data,
        current: {
          ...state.plans.data.current,
          scheduledCancel: undefined,
        },
      },
    },
  }
}

function cancelCurrentSubscription(state, { scheduledCancel }) {
  return {
    ...state,
    plans: {
      ...state.plans,
      data: {
        ...state.plans.data,
        current: {
          ...state.plans.data.current,
          scheduledCancel,
        },
      },
    },
  }
}

const setFolderPath = (state, { picker, boardId, folderId }) => {
  return {
    ...state,
    app: {
      ...state.app,
      pickersContentNavigation: {
        ...state.app.pickersContentNavigation,
        [picker]: {
          boardId,
          folderId,
        },
      },
    },
  }
}

const setGeoData = (state, data) => {
  return {
    ...state,
    app: {
      ...state.app,
      geoData: data,
    },
  }
}

const setFolderShortcut = (state, shortcut) => {
  return {
    ...state,
    app: {
      ...state.app,
      sharedFolder: {
        ...state.app.sharedFolder,
        folderPath: [...state.app.sharedFolder.folderPath, shortcut],
      },
    },
  }
}

const deleteFolderShortcut = (state, shortcut) => {
  let updatedFolderPath
  if (shortcut) {
    if (Array.isArray(shortcut)) {
      updatedFolderPath = []
    } else {
      updatedFolderPath = state.app.sharedFolder.folderPath.filter(
        (item) => item !== shortcut,
      )
    }
  } else {
    updatedFolderPath = state.app.sharedFolder.folderPath.slice(0, -1)
  }

  return {
    ...state,
    app: {
      ...state.app,
      sharedFolder: {
        ...state.app.sharedFolder,
        folderPath: updatedFolderPath,
      },
    },
  }
}

const updateSharedFolderData = (state, shortcutId, boardId, folderId) => {
  return {
    ...state,
    app: {
      ...state.app,
      sharedFolder: {
        ...state.app.sharedFolder,
        shortcutId,
        boardId,
        folderId,
      },
    },
  }
}

const setSharedFolder = (state, folder) => {
  if (!folder) {
    return {
      ...state,
      app: {
        ...state.app,
        sharedFolder: {
          ...state.app.sharedFolder,
          folders: [],
        },
      },
    }
  }

  const isDuplicate = state.app.sharedFolder.folders.some(
    (item) => item.id === folder.id,
  )

  if (!isDuplicate) {
    const newArray = [...state.app.sharedFolder.folders, folder]
    return {
      ...state,
      app: {
        ...state.app,
        sharedFolder: {
          ...state.app.sharedFolder,
          folders: newArray,
        },
      },
    }
  }

  return state
}

export default (state = {}, action) => {
  switch (action.type) {
    case FOLDER_ACTION_TYPE.SET_FOLDER:
      return setFolders(state, action.boardId, action.folders)
    case BOARD_ACTION_TYPE.SET_FOLDER_PATH:
      return setFolderPath(state, action)
    case BOARD_ACTION_TYPE.SET_BOARD:
      return setBoard(state, action.boardId, action.board, action.folder)

    case CLIENT_CONFIG_TYPE.SET_CLIENT_CONFIG:
      return setClientConfig(state, action.data, action.clientFeature)

    case COMMON_ACTION_TYPE.RESET_STATE:
      return initialState

    case USER_ACTION_TYPE.SET_USER_PROPERTIES:
      return {
        ...state,
        profileInfo: {
          ...state.profileInfo,
          name: action.name,
        },
      }

    case PROFILE_INFO_ACTION_TYPE.UPDATE_PROFILE_INFO:
    case PROFILE_INFO_ACTION_TYPE.SYNC_PROFILE_INFO:
      return syncProfileInformation(state, action)
    case PROFILE_INFO_ACTION_TYPE.SET_PAYPAL_AUTHORIZATION:
      return setPayPalAuthorization(state, action)

    case BOARD_ACTION_TYPE.CREATE_BOARD: {
      return setActionInProgress({
        ...createBoard(
          state,
          action.owner,
          action.board,
          action.properties,
          action.picker,
        ),
        profileInfo: {
          ...state.profileInfo,
          activeBoards: [...state.profileInfo.activeBoards, action.board],
          usage: {
            ...state.profileInfo.usage,
            boards: state.profileInfo.usage.boards + 1,
          },
        },
      })
    }
    case BOARD_ACTION_TYPE.CREATE_BOARD_FAKE: {
      const { board, owner, key } = action
      const node = { ...action.properties, board, owner, key }
      return createFakeBoard(state, action.owner, action.board, node)
    }
    case BOARD_ACTION_TYPE.CREATE_PAGES_BOARD: {
      const { board, owner, key } = action
      const node = { ...action.properties, board, owner, key }
      return createPagesBoard(state, action.owner, action.board, node)
    }
    case FOLDER_ACTION_TYPE.ADD_FOLDER: {
      return setActionInProgress(
        addFolder(
          state,
          action.board,
          action.folder,
          action.key,
          action.properties,
        ),
      )
    }
    case PAGE_ACTION_TYPE.ADD_PAGE: {
      return setActionInProgress(
        addPage(
          state,
          action.payload.board,
          action.payload.folder,
          action.payload.key,
          action.payload.properties,
        ),
      )
    }
    case FOLDER_ACTION_TYPE.ADD_FOLDER_FAKE:
      return addFakeFolder(
        state,
        action.board,
        action.folder,
        action.key,
        action.properties,
        !!action?.isPage,
        action.picker,
      )
    case CONTENT_ACTION_TYPE.ADD_CONTENT: {
      return setActionInProgress(
        addContent(
          state,
          action.board,
          action.folder,
          action.properties,
          action.key,
        ),
      )
    }
    case CONTENT_ACTION_TYPE.COPY_TEMPLATE: {
      return copyTemplateReducer(state, action, undoStackWithContentAdded)
    }

    case CONTENT_ACTION_TYPE.REVERT_COPY_TEMPLATE: {
      return deleteNodes(state, action.boardId, action.folderId, [
        action.pageId,
      ])
    }

    case NODE_ACTION_TYPE.SET_FORM_INTEGRATION_STATE: {
      return setFormIntegrationState(
        state,
        action.board,
        action.folder,
        action.node,
        action.integrationState,
      )
    }

    case NODE_ACTION_TYPE.SET_PROPERTIES:
      return setActionInProgress(
        setProperties(
          state,
          action.board,
          action.folder,
          action.node,
          action.properties,
        ),
      )
    case NODE_ACTION_TYPE.COPY_NODES:
      return copyNodesReducer(state, action, undoStackWithContentAdded)

    case NODE_ACTION_TYPE.MOVE_NODES:
      return setActionInProgress(
        moveNodes(
          state,
          action.board,
          action.folder,
          action.nodes,
          action.fromFolder,
          action.before,
        ),
      )
    case NODE_ACTION_TYPE.DELETE_NODES:
      return setActionInProgress(
        deleteNodes(state, action.board, action.folder, action.nodes),
      )
    case EDIT_LINK_ACTION_TYPE.SET_EDIT_LINK_ERROR:
      return editLinkError(state, action.errorMessage)
    case EDIT_LINK_ACTION_TYPE.SET_EDIT_LINK_PAGE_STEP:
      return editLinkPageStep(state, action.pageStep)
    case EDIT_LINK_ACTION_TYPE.SET_EDIT_LINK_LOADING:
      return editLinkLoading(state, action.loading)
    case EDIT_LINK_ACTION_TYPE.SET_EDIT_LINK_TITLE:
      return editLinkTitle(state, action.title)
    case EDIT_LINK_ACTION_TYPE.RESET_EDIT_LINK_STATE:
      return resetEditLinkState(state)
    case COMMON_ACTION_TYPE.SET_FORM_ERRORS:
      return setFormErrors(state, action.formErrors)
    case BUTTON_ACTION_TYPE.SET_BUTTON_ERROR:
      return setButtonError(state, action.buttonError)
    case BUTTON_ACTION_TYPE.SET_BUTTON_TYPE:
      return setButtonType(state, action.buttonType)
    case BUTTON_ACTION_TYPE.SET_BUTTON_GEO_DATA:
      return setButtonGeoData(state, action.geoData)
    case GOOGLE_SHEETS_ACTION_TYPE.SET_IS_GOOGLE_SHEETS_URL_VALID:
      return setIsGoogleSheetsUrlValid(state, action.isGoogleSheetsUrlValid)
    case VIDEO_ACTION_TYPE.SET_VIDEO_ERROR:
      return videoError(state, action.error)
    case VIDEO_ACTION_TYPE.SET_VIDEO_URL:
      return videoStreamingUrl(state, action.url)
    case VIDEO_ACTION_TYPE.SET_VIDEO_ID:
      return videoStreamingId(state, action.id)
    case BOARD_ACTION_TYPE.DELETE_BOARD:
      return setActionInProgress(deleteBoard(state, action.board))
    case FOLDER_ACTION_TYPE.DELETE_FOLDER:
      return deleteFolder(state, action)

    case UPDATES_ACTION_TYPE.RECEIVED_UPDATES_MESSAGES:
      return receiveUpdatesMessages(state, action)
    case UPDATES_ACTION_TYPE.RECEIVED_READ_UNREAD_MESSAGES:
      return receiveReadUnReadMessages(state, action)
    case UPDATES_ACTION_TYPE.DELETE_UPDATES_MESSAGE:
      return deleteUpdatesMessage(state, action.boardId, action.mid)

    case UPDATES_ACTION_TYPE.SAVE_UN_POST_UPDATES_MESSAGE:
      return saveUnPostedUpdatesMessage(state, action)
    case UPDATES_ACTION_TYPE.REMOVE_UN_POST_UPDATES_MESSAGE:
      return removeUnPostedUpdatesMessage(state, action)

    case UPLOAD_ACTION_TYPE.ADD_UPLOAD:
      return addUpload(state, action.id)
    case UPLOAD_ACTION_TYPE.REMOVE_UPLOAD:
      return removeUpload(state, action.id)

    case NODE_ACTION_TYPE.END_EDITING_CONTENT_NODE:
      return stateEndEditingContentNode(state)

    case UNDO_REDO_ACTION_TYPE.UNDO:
      return setActionInProgress(undoRedo(state, action.board, true))
    case UNDO_REDO_ACTION_TYPE.REDO:
      return setActionInProgress(undoRedo(state, action.board, false))
    case UNDO_REDO_ACTION_TYPE.RESET_UNDO_STACK:
      return resetUndoStack(state)

    case COMMON_ACTION_TYPE.SET_LOADING:
      return stateSetLoading(state, action.loading)
    case BOARDS_ORDER_ACTION_TYPE.SET_BOARDS_ORDER:
      return stateSetBoardsOrder(state, action.order)

    case MEMBERS_ACTION_TYPE.ADD_MEMBERS:
      return addMembers(state, action.board, action.emails, action.permission)
    case MEMBERS_ACTION_TYPE.REMOVE_MEMBERS:
      return removeMembers(state, action.board, action.emails)
    case BOARD_ACTION_TYPE.LEAVE_BOARD:
      return deleteBoard(state, action.board)
    case PERMISSION_ACTION_TYPE.UPDATE_PERMISSION:
      return updatePermission(
        state,
        action.board,
        action.email,
        action.permission,
      )
    case PERMISSION_ACTION_TYPE.SET_PERMISSION:
      return stateSetPermission(
        state,
        action.board,
        action.permissions,
        action.final,
      )
    case PERMISSION_ACTION_TYPE.DELETE_PERMISSION:
      return stateDeletePermission(state, action.board, action.recipient)

    case BOARD_ACTION_TYPE.REMOVE_RECEIVED_DOCUMENT:
      return removeBoard(state, action.documentId)
    case CONTENT_ACTION_TYPE.SET_USER_CONTENT_SETTINGS:
      return stateSetUserContentSettings(state, action.settings)
    case COMMON_ACTION_TYPE.SET_INVITE_LINK:
      return setInvite(state, action)

    case BULK_ACTION_TYPE.SET_BULK_ACTION:
      return stateSetBulkAction(
        state,
        action.boardId,
        action.folderId,
        action.shortcutId,
      )
    case BULK_ACTION_TYPE.SET_BULK_ACTION_TYPE:
      return stateSetBulkActionType(state, action.action)
    case BULK_ACTION_TYPE.TOGGLE_NODE_BULK_ACTION:
      return stateToggleNodeBulkAction(state, action.nodeId, action.nodeType)
    case BULK_ACTION_TYPE.RESET_BULK_ACTION:
      return stateResetBulkAction(state)
    case TOAST_ACTION_TYPE.OPEN_TOAST_MESSAGE:
      return {
        ...state,
        app: {
          ...state.app,
          toast: {
            msg: action.message,
            global: action.global,
            showIcon: action.showIcon,
            customIcon: action.customIcon,
            onClose: action.onClose,
          },
        },
      }
    case TOAST_ACTION_TYPE.CLOSE_TOAST_MESSAGE:
      return {
        ...state,
        app: {
          ...state.app,
          toast: {
            msg: undefined,
            global: true,
            customIcon: undefined,
            onClose: undefined,
          },
        },
      }
    case TEMPLATES_ACTION_TYPE.SET_TEMPLATES:
      return setTemplates(state, action.data)
    case ACTION_ACTION_TYPE.STOP_ACTION_IN_PROGRESS:
      return setActionInProgress(state, false)

    case ACTION_ACTION_TYPE.REVERT_ACTION:
      return {
        ...action.state,
        app: action.state.app,
      }

    case UNDO_REDO_ACTION_TYPE.SET_UNDO_ACTION:
      return setUndoAction(state, action.boardId, action.content)

    case COMMON_ACTION_TYPE.SET_UI_STATE:
      return setUiState(state, action)
    case SUBSCRIPTIONS_ACTION_TYPE.SET_AVAILABLE_SUBSCRIPTIONS_LOADING:
      return setAvailableSubscriptionsLoading(state, action.discountId)
    case SUBSCRIPTIONS_ACTION_TYPE.RESET_AVAILABLE_SUBSCRIPTIONS:
      return {
        ...state,
        plans: initialState.plans,
      }
    case SUBSCRIPTIONS_ACTION_TYPE.SET_AVAILABLE_SUBSCRIPTIONS_FAILED:
      return {
        ...state,
        plans: {
          status: 'failed',
          data: {
            current: null,
            plans: [],
          },
        },
      }

    case SET_PERSIST_USER.SET_PERSIST_USER:
      return {
        ...state,
        persistUser: action.isPersist,
      }

    case SUBSCRIPTIONS_ACTION_TYPE.SET_AVAILABLE_SUBSCRIPTIONS:
      return setAvailableSubscriptions(state, action)
    case SUBSCRIPTIONS_ACTION_TYPE.RENEW_CURRENT_SUBSCRIPITON:
      return renewCurrentSubscription(state, action)
    case SUBSCRIPTIONS_ACTION_TYPE.CANCEL_CURRENT_SUBSCRIPITON:
      return cancelCurrentSubscription(state, action)
    case COMMON_ACTION_TYPE.SET_GEO_DATA:
      return setGeoData(state, action.data)
    case FOLDER_ACTION_TYPE.SET_FOLDER_SHORTCUT:
      return setFolderShortcut(state, action.shortcut)
    case FOLDER_ACTION_TYPE.DELETE_FOLDER_SHORTCUT:
      return deleteFolderShortcut(state, action.shortcut)
    case FOLDER_ACTION_TYPE.SET_SHARED_FOLDER:
      return setSharedFolder(state, action.folder)
    case FOLDER_ACTION_TYPE.UPDATE_SHARED_FOLDER_DATA:
      return updateSharedFolderData(
        state,
        action.shortcutId,
        action.boardId,
        action.folderId,
      )

    case CAMPAIGN_ACTION_TYPE.GET_CAMPAIGN_SUCCESS:
      return campaignReducers.getCampaignSuccess(state, action)
    case CAMPAIGN_ACTION_TYPE.GET_CAMPAIGN_BANNERS_SUCCESS:
      return campaignReducers.getCampaignBannersSuccess(state, action)
    case CAMPAIGN_ACTION_TYPE.HIDE_CAMPAIGN_BANNER:
      return campaignReducers.hideCampaignBanner(state, action)

    default:
      return state
  }
}
