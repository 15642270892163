import { Node, NodeType } from '../../../models'

export enum BoardMode {
  action = 'action',
  select = 'select',
  read = 'read',
  write = 'write',
  offline = 'offline',
}

export interface INode {
  isSelected: (nodeId: string) => boolean
  onClick: (params: {
    nodeId: string
    type: NodeType
    unSelectable?: boolean
    isNotSameFolderIdForBulkAction?: boolean
  }) => void
  onSelect: (nodeId: string, type: NodeType) => void
  moveNode: (nodeId: string, beforeNodeId: string | undefined) => void
}

export interface IBulkSelection {
  isInSelectionMode: boolean
  hasAction: boolean
  isSelected: (nodeId: string) => boolean
  startSelection: (params: {
    boardId: string
    folderId: string
    shortcutId?: string
  }) => void
  toggleNodeSelection: (nodeId: string, type: NodeType) => void
  isPageOnlySelected: boolean
}

export interface ILoadImage {
  hasError: boolean
  handleImageError: () => void
}

export interface IOpenNode {
  openFolder: (boardId: string, nodeId: string) => void
  previewNode: (nodeId: string, hideParents?: boolean) => void
}

export interface IReact {
  x: number
  y: number
  height: number
  width: number
}

export interface ITile {
  node: Node
  rect: IReact
}
export type LayoutState = {
  height: number
  tiles: ITile[]
}

export interface ILocation {
  x: number
  y: number
}
export interface IDeltaLocation {
  dragDeltaX: number
  dragDeltaY: number
  locationX: number
  locationY: number
}

export interface IDragAndDrop {
  layout: LayoutState
  setItems: (items: Node[]) => void
  onDrag: ((startIndex: number, dropLocation: IDeltaLocation) => void) | null
  onDragEnd: ((startIndex: number) => void) | null
}
