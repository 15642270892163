import Tiler from './Tiler'
import settings from '../config'
import { IDeltaLocation, IReact, LayoutState } from '../models/nodes'
import { Node, NodeType } from '../../../models'

const { columnSpacing } = settings

export function rowCount(type: NodeType) {
  return settings.nodeRows[type]
}

export function buildLayout(nodes: Node[]): LayoutState {
  const tiler = new Tiler()

  const tiles = nodes.map((node) => ({
    node,
    rect: tiler.addTile(rowCount(node.type), 1),
  }))

  return {
    height: tiler.size.height + columnSpacing,
    tiles,
  }
}

export function getDropFrames(nodes: Node[], dragNodeIndex: number) {
  const tiler = new Tiler()
  const dragNode = nodes[dragNodeIndex]

  const dragRowCount = rowCount(dragNode.type)

  const frames = []
  let dragRect: IReact = { x: 0, y: 0, width: 0, height: 0 }
  let index = 0

  nodes.forEach((node) => {
    const dropRect = tiler.clone().addTile(dragRowCount, 1)
    if (node !== dragNode) {
      frames.push({ index, rect: dropRect })
      index += 1
      tiler.addTile(rowCount(node.type), 1)
    } else {
      dragRect = dropRect
    }
  })

  const dropRect = tiler.addTile(dragRowCount, 1)
  frames.push({ index, rect: dropRect })

  const dragStartX = dragRect.x + dragRect.width / 2
  const dragStartY = dragRect.y + dragRect.height / 2

  return frames.map((frame) => {
    const { rect } = frame
    return {
      index: frame.index,
      rect: {
        x: rect.x - dragStartX,
        y: rect.y - dragStartY,
        width: rect.width,
        height: rect.height,
        column: rect.column,
      },
    }
  })
}

export function hitIndex(
  items: Node[],
  startIndex: number,
  dropLocation: IDeltaLocation,
) {
  const { dragDeltaX, dragDeltaY } = dropLocation
  const dropFrames = getDropFrames(items, startIndex)

  let hitIndexNumber = -1
  let min = 100000000

  dropFrames.forEach((frame) => {
    const { rect } = frame
    if (
      (dragDeltaX <= rect.x + rect.width + columnSpacing / 2 &&
        rect.column === 0) ||
      (dragDeltaX >= rect.x - columnSpacing / 2 && rect.column === 1)
    ) {
      const dy = Math.abs(dragDeltaY - (rect.y + rect.height / 2))
      if (dy < min) {
        min = dy
        hitIndexNumber = frame.index
      }
    }
  })

  return hitIndexNumber
}
