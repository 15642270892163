import { useCallback, useState } from 'react'

import { Node } from '../../../models'
import { arrayMove } from '../helpers'
import { buildLayout, hitIndex } from '../helpers/layout'
import { IDeltaLocation, IDragAndDrop, LayoutState } from '../models/nodes'

const initialLayout = {
  height: 0,
  tiles: [],
} as LayoutState

const useDragAndDrop = (
  isDragEnabled: boolean,
  moveNode: (nodeId: string, beforeNodeId: string) => void,
  isApiAvailableOrNotify: () => boolean,
): IDragAndDrop => {
  const [items, setItemsState] = useState<Node[]>([])
  const [dropIndex, setDropIndex] = useState<number>(0)
  const [layout, setLayout] = useState<LayoutState>(initialLayout)

  const setItems = useCallback((itemsParameter: Node[]) => {
    setItemsState(itemsParameter)
    setLayout(buildLayout(itemsParameter))
  }, [])

  const onDragEnd = (startIndex: number) => {
    if (!isApiAvailableOrNotify()) return

    const nodeIndex = dropIndex > startIndex ? dropIndex + 1 : dropIndex
    const beforeNode = items[nodeIndex]
    const node = items[startIndex]
    const newItems = arrayMove(items, startIndex, dropIndex)

    setItems(newItems)
    setLayout(buildLayout(newItems))
    moveNode(node?.id, beforeNode?.id)
  }

  const onDrag = useCallback(
    (startIndex: number, dropLocation: IDeltaLocation) => {
      const newDropIndex = hitIndex(items, startIndex, dropLocation)

      if (newDropIndex !== dropIndex) {
        const newItems = arrayMove(items, startIndex, newDropIndex)
        setDropIndex(newDropIndex)

        const newLayout = buildLayout(newItems)
        if (startIndex >= 0 && newDropIndex >= 0) {
          const dragTile = newLayout.tiles.splice(newDropIndex, 1)[0]
          // keep dragged node layout at end of array for animation to work correctly
          newLayout.tiles.splice(newLayout.tiles.length - 3, 0, dragTile)
        }
        setLayout(newLayout)
      }
    },
    [items, dropIndex],
  )

  return {
    layout,
    setItems,
    onDrag: isDragEnabled ? onDrag : null,
    onDragEnd: isDragEnabled ? onDragEnd : null,
  }
}

export default useDragAndDrop
