import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import useConfirmDialog from '@hooks/useConfirmDialog'
import useUserFeatures, { Features } from '@hooks/useUserFeatures'
import { getBoardIdAndOwner, getRootFolderId } from '@helpers/NodeIdGenerator'
import useBoardEvents from '@features/analytics/useBoardEvents'
import { PaywallSourceEnum } from '@features/analytics/useAccountSubscriptionEvents'
import usePaywall from '@hooks/usePaywall'

import { useSelectorFactory } from './useSelectorFactory'
import useRouterParams from './useRouterParams'
import {
  getBoard,
  getBoardPermissions,
  getBoardPermissionsLoaded,
  getBoardRole,
  getFolder,
  getFolders,
  getIsBoardActive,
  getParentFolder,
} from '../selectors'

import { Board, Folder, Permission, Role } from '../app/models'
import { useAuth } from './useAuth'
import { boardActions } from '../actions'
import { useToast } from './useToast'
import { mapPermissionToAnalyticsBoardRole } from '../utils/permissionHelper'

interface IUseBoard {
  boardId: string
  board: Board | undefined
  folder: Folder
  folders: { [id: string]: Folder }
  folderId: string
  permissions: Permission[]
  totalBoardMembersCount: number
  totalBoardEditorsCount: number
  role: Role
  parent: Folder
  isActive: boolean
  isEditor: boolean
  isEmpty: boolean
  isFolderEmpty: boolean
  isRoot: boolean
  isReadOnly: boolean
  isPermissionLoaded: boolean
  isShared: boolean
  canShare: boolean
  allowCopy: boolean
  allowShare: boolean
  limitReached: boolean
  allowShareFolderAllowed: boolean
  isSponsored: boolean
  isPage: (fid: string) => boolean
  onActivate: (
    source:
      | PaywallSourceEnum.ACTIVATE_BOARD_LIST
      | PaywallSourceEnum.ACTIVATE_BOARD_SCREEN
      | PaywallSourceEnum.ACTIVATE_MY_BOARD_LIST
      | PaywallSourceEnum.ACTIVATE_SHARED_BOARD_LIST,
    mode?: 'activate' | 'upgrade',
  ) => void
  isOwnBoard: boolean
  isPageBoard: boolean
  isActiveBoard: boolean
  isCopyAllowedByRole: boolean
}

export const useBoard = (params?: {
  boardId?: string
  folderId?: string
  isFreeUserMenu?: boolean
}): IUseBoard => {
  const { appBoardActivateClick } = useBoardEvents()
  const { t } = useTranslation()
  const toast = useToast()
  const dispatch = useDispatch()
  const confirm = useConfirmDialog()
  const { user } = useAuth()

  const {
    boardId: selectedBoardId,
    folderId: selectedFolderId,
    bid,
  } = useRouterParams()

  const boardId = params?.boardId || selectedBoardId
  const folderId = params?.folderId || selectedFolderId

  const board = useSelectorFactory(getBoard, boardId)
  const { openPaywall } = usePaywall()

  const role = useSelectorFactory(getBoardRole, boardId, user?.email)
  const rootFolder = useSelectorFactory(getFolder, boardId, board?.rootFolderId)

  const rawPermissions = useSelectorFactory(getBoardPermissions, boardId) || {}
  const permissions = (Object.values(rawPermissions) as Permission[]).filter(
    (p) => p.recipient !== user?.email,
  )
  const folders = useSelectorFactory(getFolders, boardId) || {}
  const folder = useSelectorFactory(getFolder, boardId, folderId)
  const parent = useSelectorFactory(getParentFolder, boardId, folderId)
  const isActive = useSelectorFactory(
    getIsBoardActive,
    boardId ? getBoardIdAndOwner(boardId)[1] : bid,
  )

  const isRoot = getRootFolderId(boardId) === folderId
  const isPermissionLoaded = useSelectorFactory(
    getBoardPermissionsLoaded,
    boardId,
  )

  const totalBoardMembersCount = board?.summary?.permissions?.total || 0
  const totalBoardEditorsCount = board?.summary?.permissions?.write || 0
  const isEditor = role === Role.WRITE
  const isEmpty = rootFolder?.content.length === 0
  const isFolderEmpty = folder?.content.length === 0
  const isReadOnly = [Role.READ, Role.SHARE].includes(role)
  const canShare = [Role.OWN, Role.WRITE, Role.SHARE].includes(role)
  const isShared = permissions.length > 0
  const allowCopy = Boolean(
    board && (isEditor || board?.isOwn || board?.options?.copyAllowed),
  )

  const allowShare = Boolean(board?.options?.shareAllowed)
  const allowShareFolderAllowed = Boolean(
    board?.options?.shareFolderAllowed && board?.options?.shareAllowed,
  )

  const isPage = (fid: string) => Boolean(folders?.[fid]?.isPage)

  const { limit, limitReached: limitReachedSelector } = useUserFeatures(
    Features.boards,
  )

  const limitReached =
    limitReachedSelector || (params?.isFreeUserMenu && board?.isOwn)

  const isSponsored = Boolean(board?.options?.notCountedTowardsLimit)

  const isOwnBoard = Boolean(board?.isOwn)
  const isPageBoard = Boolean(board?.isPageBoard)
  const isActiveBoard = Boolean(board?.isActive)

  const isCopyAllowedByRole = board?.options?.copyAllowed
    ? true
    : isOwnBoard || !isReadOnly

  const onActivate: IUseBoard['onActivate'] = useCallback(
    (source) => {
      appBoardActivateClick(
        limitReached ? 'upgrade' : 'activate',
        mapPermissionToAnalyticsBoardRole({ role, isOwnBoard }),
      )

      if (limitReached) {
        openPaywall({ source })
        return
      }

      confirm({
        title: t('activate_dialog_title'),
        message: t('activate_dialog_body').replace('%@', limit.toString()),
        confirmText: t('activate_dialog_button_activate'),
        cancelText: t('activate_dialog_button_cancel'),
        onConfirm: () => {
          dispatch(boardActions.activateBoard(boardId || selectedBoardId))
          toast(t('board_activated_toast'))
        },
      })
    },
    [
      limit,
      boardId,
      limitReached,
      selectedBoardId,
      isOwnBoard,
      role,
      t,
      toast,
      confirm,
      dispatch,
      openPaywall,
      appBoardActivateClick,
    ],
  )

  return {
    boardId: boardId || selectedBoardId,
    board,
    folder,
    folderId,
    folders,
    permissions,
    totalBoardMembersCount,
    totalBoardEditorsCount,
    role,
    parent,
    isActive,
    isEditor,
    isEmpty,
    isFolderEmpty,
    isRoot,
    isReadOnly,
    isPermissionLoaded,
    canShare,
    isShared,
    allowCopy,
    allowShare,
    allowShareFolderAllowed,
    onActivate,
    isPage,
    limitReached,
    isSponsored,
    isOwnBoard,
    isPageBoard,
    isActiveBoard,
    isCopyAllowedByRole,
  } as const
}

export default useBoard
