import { FC, useEffect, useState } from 'react'
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import { useTranslation } from 'react-i18next'
import { Overlay } from 'boards-web-ui'

import useBoard from '@hooks/useBoard'
import useFolder from '@hooks/useFolder'
import useScrollArea from '@hooks/useScrollArea'

import PageEditor from '@features/editorPages/components/PageEditor'
import usePageAPI from '@features/toolbar/hooks/usePageAPI'
import usePageEvents from '@features/analytics/usePageEvents'
import useNodeCreatorAPI from '@features/nodeEditor/hooks/useNodeCreatorAPI'
import useNodeDeletorAPI from '@features/nodeEditor/hooks/useNodeDeletorAPI'
import useNodeEditorAPI from '@features/nodeEditor/hooks/useNodeEditorAPI'
import useAutoPopulatePageTitle from '@features/editorPages/hooks/useAutoPopulatePageTitle'
import { ICreateOrUpdateNode } from '@features/nodeEditor/models/IUseNodeCreatorAPI'

import { Node } from 'app/models'
import { ScrollAreaContainer } from 'ui/models/ScrollArea'
import { CSS_TRANSITION_TIME } from 'constants/CSSTransitionTime'
import useCopyNodes from './hooks/useCopyNodes'

const UpdatePage: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { update } = usePageAPI()
  const { pageId } = useParams()
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const { scrollToBottom } = useScrollArea()
  const isNew = !!searchParams.get('create')
  const { isPageBoard, boardId, folderId, isReadOnly } = useBoard()
  const { folder } = useFolder(boardId, pageId)
  const { editPageEvent, pageCreateContentEvent, createPageEvent } =
    usePageEvents()
  const editNode = useNodeEditorAPI(boardId, folder?.id)
  const createNode = useNodeCreatorAPI(boardId, folder?.id)
  const { deleteNode } = useNodeDeletorAPI(boardId, pageId || '')
  const [title, setTitle] = useState<string>(
    isNew && folder?.title === t('page_my_page')
      ? ''
      : folder?.title || t('page_my_page'),
  )
  const [displayEditor, setDisplayEditor] = useState(false)
  const autoPopulatePageTitle = useAutoPopulatePageTitle()
  const copyNodes = useCopyNodes(boardId, folder?.id)
  const isCopiedFromTemplate = location.state?.isCopiedFromTemplate || false
  const templateCategory = location.state?.templateCategory
  const templateName = location.state?.templateName

  useEffect(() => {
    if (isCopiedFromTemplate && folder) {
      createPageEvent(folder, boardId, isPageBoard, {
        template_category_name: templateCategory,
        template_name: templateName,
        source: 'template',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    boardId,
    isPageBoard,
    isCopiedFromTemplate,
    templateCategory,
    templateName,
    createPageEvent,
  ])

  useEffect(() => {
    if (folder && !isReadOnly) {
      setDisplayEditor(true)
    }
  }, [folder, isReadOnly, setDisplayEditor])

  useEffect(() => {
    setTitle(
      isNew && folder?.title === t('page_my_page')
        ? ''
        : folder?.title || t('page_my_page'),
    )
  }, [isNew, folder?.title, t])

  const handleOnDoneClick = () => {
    if (!folder.title || title !== folder.title) {
      const titleWithDefaults = autoPopulatePageTitle(folder.content, title)

      update(boardId, folderId, folder.id, {
        title: titleWithDefaults,
        themeId: folder?.themeId,
      })
    }

    setDisplayEditor(false)

    if (!isNew) {
      editPageEvent(folder, boardId, isPageBoard)
    }

    setTimeout(() => {
      navigate(`../`)
    }, 400)
  }

  const handleCreateNode: ICreateOrUpdateNode = (node, extraParams) => {
    const nodeId = createNode(node)

    pageCreateContentEvent(node as Node, boardId, folder.id, extraParams)

    setTimeout(() => {
      scrollToBottom(ScrollAreaContainer.PAGE)
    }, 100)

    return nodeId
  }

  if (!folder || isReadOnly) return null

  return (
    <Overlay
      index={isNew ? 0 : 1}
      position="fixed"
      color={'rgba(0, 0, 0, 0.2)'}
    >
      <CSSTransition
        in={displayEditor}
        classNames={isNew ? 'edit-new-page' : 'create-page'}
        timeout={CSS_TRANSITION_TIME}
        unmountOnExit={isNew}
      >
        <PageEditor
          title={title}
          folder={folder}
          updateTitle={setTitle}
          createNode={handleCreateNode}
          updateNode={editNode}
          deleteNode={deleteNode}
          onClickDone={handleOnDoneClick}
          selectAction={copyNodes}
        />
      </CSSTransition>
    </Overlay>
  )
}

export default UpdatePage
