import useBoard from '@hooks/useBoard'
import { useSelectorFactory } from '@hooks/useSelectorFactory'
import useBulk from '@hooks/bulk/useBulk'
import useBulkSelection from '@features/board/hooks/useBulkSelection'
import { boardBreadcrumbs, getBulkActionNodeTypes } from '../../selectors'
import { NodeType } from '../../app/models'

const ALLOWED_TYPES_IN_PAGES = [
  NodeType.TEXT,
  NodeType.VIDEO,
  NodeType.IMAGE,
  NodeType.LINK,
]

const useBulkActionsPermissions = (params?: {
  boardId?: string
  folderId?: string
}) => {
  const { boardId, isReadOnly, folder, board, isActive, isCopyAllowedByRole } =
    useBoard({
      boardId: params?.boardId,
      folderId: params?.folderId,
    })

  const breadcrumbs = useSelectorFactory(boardBreadcrumbs, boardId, folder?.id)

  const {
    bulk: { nodes, action, contentId, folderId: bulkFolderId },
  } = useBulk()
  const { isSelected } = useBulkSelection()
  const nodeTypes = useSelectorFactory(getBulkActionNodeTypes)

  const isTargetInSelectedKeys = breadcrumbs
    .map((n: { id: string }) => n.id)
    .filter(isSelected).length

  const hasAction = action !== undefined
  const hasKeysSelected = nodes.length > 0

  const isSameBoard = contentId === boardId
  const isSameFolder = bulkFolderId === folder?.id

  const isAllNodesAllowed =
    !folder?.isPage ||
    nodeTypes.filter((type: NodeType) => !ALLOWED_TYPES_IN_PAGES.includes(type))
      .length === 0

  const isCopyDisabled =
    !(isActive || board?.isPageBoard) ||
    !isCopyAllowedByRole ||
    !isAllNodesAllowed

  const isMoveDisabled =
    isCopyDisabled ||
    !isSameBoard ||
    isTargetInSelectedKeys ||
    isSameFolder ||
    isReadOnly

  return {
    isReadOnly,
    showActions: hasKeysSelected && !hasAction && isSameBoard,
    isCopyDisabled,
    isMoveDisabled,
    nodes,
    folder,
  }
}

export default useBulkActionsPermissions
