import { AddPageData } from '@features/toolbar/models'
import optimisticUI, { OptimisticApiCall } from 'actions/optimisticUI'

export const enum ACTION_TYPE {
  ADD_PAGE = 'ADD_PAGE',
}

interface IAddPage {
  board: string
  folder: string
  key: string
  properties: AddPageData
}

const addPageOptimistic = (
  payload: IAddPage,
  apiCall: OptimisticApiCall,
  onError?: () => void,
) =>
  optimisticUI.createOptimisticAction({
    type: ACTION_TYPE.ADD_PAGE,
    payload,
    apiCall,
    onError,
  })

const actions = {
  addPageOptimistic,
} as const

export default actions
