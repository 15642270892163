import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { INodeProps } from '@features/nodeEditor/models/IUseNodeCreatorAPI'
import usePageEvents from '@features/analytics/usePageEvents'

import { addPage } from '_firebase/contentAPI'
import { Folder } from 'app/models'
import { AddPageData, UpdatePageData } from '../models'
import { nodeActions, pageActions } from '../../../../actions'
import useIsApiAvailableOrNotify from '../../../../hooks/useIsApiAvailableOrNotify'

interface CreatePageParams {
  boardId: string
  folderId: string
  key: string
  node: AddPageData
  sourceFolder: Folder
  isPageBoard: boolean
}
export interface AddPageApiResult {
  id: string
  properties: {
    shortcut: string
  }
}

const usePageAPI = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isApiAvailableOrNotify = useIsApiAvailableOrNotify()
  const { createPageEvent } = usePageEvents()

  const handleAddPage = useCallback(
    async (
      board: string,
      folder: string,
      key: string,
      properties: AddPageData,
      sourceFolder: Folder,
      isPageBoard: boolean,
    ) => {
      const result: AddPageApiResult = await addPage({
        board,
        folder,
        key,
        properties,
      })

      createPageEvent(
        { ...sourceFolder, shortcut: result.properties.shortcut },
        board,
        isPageBoard,
      )
    },
    [createPageEvent],
  )
  const create = useCallback(
    ({
      boardId,
      folderId,
      key,
      node,
      sourceFolder,
      isPageBoard,
    }: CreatePageParams) => {
      if (!isApiAvailableOrNotify()) return

      const payload = {
        board: boardId,
        folder: folderId,
        key,
        properties: node,
      }

      dispatch(
        pageActions.addPageOptimistic(
          payload,
          () =>
            handleAddPage(
              boardId,
              folderId,
              key,
              node,
              sourceFolder,
              isPageBoard,
            ),
          () => navigate(`/${boardId}/${folderId}`),
        ),
      )
    },
    [dispatch, isApiAvailableOrNotify, handleAddPage, navigate],
  )

  const update = useCallback(
    (
      boardId: string,
      folderId: string,
      contentId: string,
      node: UpdatePageData,
    ) => {
      if (!isApiAvailableOrNotify()) return

      dispatch(
        nodeActions.setProperties(
          boardId,
          folderId,
          contentId,
          node as unknown as INodeProps,
        ),
      )
    },
    [dispatch, isApiAvailableOrNotify],
  )

  return {
    create,
    update,
  } as const
}

export default usePageAPI
