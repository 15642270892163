import { useCallback } from 'react'

const SCROLL_BOUNDARY_THRESHOLD = 20

const useScroll = () => {
  const scrollToTop = useCallback((ref: React.RefObject<HTMLElement>) => {
    if (ref.current) {
      ref.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
  }, [])

  const scrollToElement = useCallback(
    (
      ref: React.RefObject<HTMLElement>,
      customOptions: ScrollIntoViewOptions = {},
      index?: number | null,
      height?: number | null,
    ) => {
      const options = {
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      } as ScrollIntoViewOptions

      if (ref.current) {
        ref.current.scrollIntoView({ ...options, ...customOptions })
      }
      if (index && ref.current && height) {
        ref.current?.scrollTo(0, index * height)
      }
    },
    [],
  )

  const getScrollTop = useCallback(
    (ref: React.RefObject<HTMLElement>) => ref.current?.scrollTop || 0,
    [],
  )

  const isScrollAtBoundary = useCallback(
    (ref?: React.RefObject<HTMLElement>) => {

      if (!ref?.current) {
        return { isTop: false, isBottom: false }
      }
      
      const isTop = ref.current.scrollTop <= SCROLL_BOUNDARY_THRESHOLD

      const isBottom =
        ref.current.scrollHeight -
        ref.current.scrollTop -
        ref.current.clientHeight <=
        SCROLL_BOUNDARY_THRESHOLD

      return { isTop, isBottom }
    },
    [],
  )

  return {
    scrollToTop,
    scrollToElement,
    getScrollTop,
    isScrollAtBoundary,
  }
}

export default useScroll
