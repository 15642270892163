import Pica from 'pica'

export const cropAndResizeImage = async (
  imageBuffer: ArrayBuffer,
  cropArea: { x: number; y: number; width: number; height: number },
): Promise<Buffer> => {
  const blob = new Blob([imageBuffer])

  const image = await createImageFromBlob(blob)

  const sourceCanvas = document.createElement('canvas')
  const sourceCtx = sourceCanvas.getContext('2d')
  if (!sourceCtx) throw new Error('Failed to get source canvas context')

  sourceCanvas.width = image.naturalWidth
  sourceCanvas.height = image.naturalHeight
  sourceCtx.drawImage(image, 0, 0, image.naturalWidth, image.naturalHeight)

  const croppedCanvas = document.createElement('canvas')
  croppedCanvas.width = cropArea.width
  croppedCanvas.height = cropArea.height
  const croppedCtx = croppedCanvas.getContext('2d')
  if (!croppedCtx) throw new Error('Failed to get cropped canvas context')

  const imageData = sourceCtx.getImageData(
    cropArea.x,
    cropArea.y,
    cropArea.width,
    cropArea.height,
  )
  croppedCtx.putImageData(imageData, 0, 0)

  const resizedCanvas = document.createElement('canvas')
  resizedCanvas.width = cropArea.width
  resizedCanvas.height = cropArea.height

  const picaInstance = new Pica()
  await picaInstance.resize(croppedCanvas, resizedCanvas)

  return convertCanvasToBuffer(resizedCanvas)
}

const createImageFromBlob = (blob: Blob): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    const url = URL.createObjectURL(blob)
    img.onload = () => {
      URL.revokeObjectURL(url)
      resolve(img)
    }
    img.onerror = (err) => {
      URL.revokeObjectURL(url)
      reject(err)
    }
    img.src = url
  })
}

const convertCanvasToBuffer = (canvas: HTMLCanvasElement): Promise<Buffer> => {
  return new Promise((resolve, reject) => {
    canvas.toBlob((blob) => {
      if (blob) {
        const reader = new FileReader()
        reader.onloadend = () => {
          if (reader.result) {
            resolve(Buffer.from(reader.result as ArrayBuffer))
          } else {
            reject(new Error('Failed to convert Blob to ArrayBuffer'))
          }
        }
        reader.onerror = (err) => reject(err)
        reader.readAsArrayBuffer(blob)
      } else {
        reject(new Error('Failed to convert canvas to Blob'))
      }
    })
  })
}
