import { AnyAction } from 'redux'
import { ThunkAction } from 'redux-thunk'
import i18n from 'translations/i18n'

import { AppState } from 'app/models/appState'
import { actionActions, toastActions } from 'actions'
import { ACTION_TYPE } from 'actions/page'

export type OptimisticApiCall = () => Promise<void>
export type OptimisticApiErrorCallback = () => void
interface OptimisticUiAction<T> {
  type: ACTION_TYPE
  payload: T
}

const TOAST_CLOSE_DELAY = 2500

const createOptimisticThunk =
  <T>(
    action: OptimisticUiAction<T>,
    apiCall: OptimisticApiCall,
    onError?: OptimisticApiErrorCallback,
  ): ThunkAction<void, AppState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const previousState = getState()

    dispatch(action)

    try {
      await apiCall()
    } catch (error) {
      if (onError) onError()

      dispatch(actionActions.revertAction(previousState))

      // Used to defer execution of the toast message dispatch
      // Ensures that the state changes from revertAction are finalized
      setTimeout(() => {
        dispatch(toastActions.openToastMessage(i18n.t('optimistic_ui_failed')))
      }, 0)

      setTimeout(() => {
        dispatch(toastActions.closeToastMessage())
      }, TOAST_CLOSE_DELAY)
    } finally {
      dispatch(actionActions.stopActionInProgress())
    }
  }

interface OptimisticActionParams<T> {
  type: ACTION_TYPE
  payload: T
  apiCall: OptimisticApiCall
  onError?: OptimisticApiErrorCallback
}

const createOptimisticAction = <T>({
  type,
  payload,
  apiCall,
  onError,
}: OptimisticActionParams<T>): ThunkAction<
  void,
  AppState,
  unknown,
  AnyAction
> => {
  return createOptimisticThunk({ type, payload }, apiCall, onError)
}
const actions = {
  createOptimisticAction,
} as const

export default actions
