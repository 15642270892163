import { memo, FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import clsx from 'clsx'
import {
  If,
  ButtonNew,
  DropDownMenu,
  DropDownPosition,
  Icon,
  Menu,
  MenuItem,
  PaypalIcon,
  SpinnerIcon,
  DisconnectIcon,
  WaringCircleIcon,
} from 'boards-web-ui'

import styles from './ConnectPayPalButton.module.css'

type Props = {
  isPayPalConnectionPending: boolean
  isPayPalDisconnectedPending: boolean
  isPaypalFormConnected?: boolean
  errorMessage?: string
  merchantPaymentsReceivable?: boolean
  merchantPrimaryEmailConfirmed?: boolean

  onConnect?: () => void
  onDisconnect: () => void
}
const ConnectPayPalButton: FC<Props> = ({
  isPayPalConnectionPending,
  isPayPalDisconnectedPending,
  isPaypalFormConnected,
  errorMessage,
  merchantPaymentsReceivable,
  merchantPrimaryEmailConfirmed,
  onConnect,
  onDisconnect,
}) => {
  const { t } = useTranslation()

  const hasPaypalConnectionIssues =
    !merchantPaymentsReceivable || !merchantPrimaryEmailConfirmed

  if (isPaypalFormConnected) {
    return (
      <>
        <div
          className={clsx(
            styles.Connected,
            (!merchantPaymentsReceivable || !merchantPrimaryEmailConfirmed) &&
              styles.ErrorState,
          )}
        >
          <div className={styles.ConnectedIndicatorWrap}>
            <Icon className={styles.Icon}>
              <PaypalIcon />
            </Icon>

            <div className={styles.IndicatorWrap}>
              <span className={styles.PayPal}>{t('button_paypal_label')}</span>
              <span
                className={clsx(
                  styles.Indicator,
                  hasPaypalConnectionIssues && styles.IndicatorWithError,
                )}
              >
                {t('label_connected')}
              </span>
            </div>
          </div>

          <If state={isPayPalDisconnectedPending}>
            <Icon
              size="20px"
              color="#3D3D3D"
              className={styles.DisconnectSpinner}
            >
              <SpinnerIcon />
            </Icon>
          </If>

          <If state={!isPayPalDisconnectedPending}>
            <div className={styles.Menu}>
              <DropDownMenu position={DropDownPosition.right} listenCustomEvent>
                <Menu>
                  <MenuItem
                    as="button"
                    type="button"
                    className={styles.UnLinkButton}
                    onClick={onDisconnect}
                  >
                    <Icon
                      color="#E33922"
                      size="20px"
                      className={styles.UnLinkIcon}
                    >
                      <DisconnectIcon />
                    </Icon>
                    Disconnect
                  </MenuItem>
                </Menu>
              </DropDownMenu>
            </div>
          </If>
        </div>

        <div className={styles.DisconnectSection}>
          {!merchantPaymentsReceivable && (
            <div className={styles.ErrorMessage}>
              <Icon size="14px" color="#e33922">
                <WaringCircleIcon />
              </Icon>
              <Trans i18nKey={'checkout_payment_paypal_restriction_error'} />
            </div>
          )}

          {!merchantPrimaryEmailConfirmed && (
            <div className={styles.ErrorMessage}>
              <Icon size="14px" color="#e33922">
                <WaringCircleIcon />
              </Icon>
              <Trans i18nKey={'checkout_payment_paypal_email_error'} />
            </div>
          )}
        </div>
      </>
    )
  }

  return (
    <div className={errorMessage ? '' : styles.ButtonOverrider}>
      <ButtonNew
        size="xl"
        errorMessage={errorMessage}
        fullWidth
        color={errorMessage ? 'red' : 'primary'}
        onClick={onConnect}
      >
        <Icon size="24px" className={styles.PaypalIcon}>
          <PaypalIcon />
        </Icon>
        &nbsp;&nbsp;
        {t('action_connect_paypal')}
        {isPayPalConnectionPending ? (
          <Icon size="24px" className={styles.SpinnerIcon}>
            <SpinnerIcon />
          </Icon>
        ) : null}
      </ButtonNew>
    </div>
  )
}

export default memo(ConnectPayPalButton)
